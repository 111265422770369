<template>
  <div>
    <div class="img-content">
      <el-image fit="fill" style="height: 600px;width: 100%;" draggable="false" :src="bg"></el-image>
      <!-- 未登录 -->
      <div v-if="!islogin" class="index-search-content">
        <div class="text-p">登录后，可查看更多信息</div>
        <div class="btn-text-p" @click="loginEmit">立即登录</div>
      </div>
      <!-- 未认证 -->
      <div v-if="!isAuthentication && islogin" class="index-search-content">
        <div class="text-p">未认证，
          <span style="color: #E7C478;cursor: pointer;" @click="handleToLink()">立即认证<i class="el-icon-arrow-right"></i></span>
        </div>
        <div class="no-authentication">您还未通过企业实名认证，认证成功后即可开启数据维护服务！</div>
      </div>
      <div v-if="isAuthentication && islogin" style="top: 30%;height: 60%;" class="index-search-content">
        <!-- 查看E查工程对比 -->
        <a-score v-if="isActive == 1" @btnActive="handleChangeType"></a-score>
        <!-- 查看工程信易贷评分 -->
        <!--<b-score v-if="isActive == 2" @btnActive="handleChangeType"></b-score>-->
        <!-- 查看国行信e融评分 -->
        <c-score v-if="isActive == 3" @btnActive="handleChangeType"></c-score>
      </div>
    </div>
    <!-- 未登录 -->
    <el-row v-show="!islogin" style="margin-top: 80px;margin-bottom: 20px;">
      <el-col :span="24">
        <div class="container">
          <gaussian-blur-data @loginEmit="loginEmit" pageName="company-credential" v-show="!islogin"></gaussian-blur-data>
        </div>
      </el-col>
    </el-row>
    <!-- 未认证 -->
    <el-row v-show="!isAuthentication && islogin" style="margin-top: 80px;margin-bottom: 20px;">
      <el-col :span="24">
        <div class="container">
          <gaussian-blur-authen @loginEmit="loginEmit" pageName="company-credential"></gaussian-blur-authen>
        </div>
      </el-col>
    </el-row>
    <!-- 表格 -->
    <el-row v-show="islogin && isAuthentication" style="margin-top: 30px;">
      <el-col :span="24">
        <div class="container" >
          <div id="affix" style="position: relative;height: 0px;overflow: hidden;top: -120px;"></div>
          <el-affix  position="top" :offset="60">
            <div class="table-title-jc">
              <div class="table-title-span-jc" :class="{active:btnActive == '1'}" @click="returnTop('qualificationsHeader','1')">资质证书 {{page.qualificationsHeader.total}}</div>
              <div class="table-title-span-jc" :class="{active:btnActive == '2'}" @click="returnTop('honorHeader','2')">荣誉信息 {{page.honorHeader.total}}</div>
              <div class="table-title-span-jc" :class="{active:btnActive == '3'}" @click="returnTop('personnelHeader','3')">技术人员 {{page.personnelHeader.total}}</div>
              <div class="table-title-span-jc" :class="{active:btnActive == '4'}" @click="returnTop('projectHeader','4')">项目业绩 {{page.projectHeader.total}}</div>
              <div class="table-title-span-jc" :class="{active:btnActive == '5'}" @click="returnTop('intellectualPatentHeader','5')">知识产权 {{page.intellectualPatentHeader.total + page.softwarePatentHeader.total}}</div>
              <div class="table-title-span-jc" :class="{active:btnActive == '6'}" @click="returnTop('taxationHeader','6')">税务荣誉 {{page.taxationHeader.total}}</div>
            </div>
          </el-affix>
          <!-- 表格 -->
          <div class="table-list">
            <!-- 资质证书 -->
            <block >
              <div id="qualificationsHeader" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
              <div class="table-list-title">资质证书<span style="color: #5D6FE9;"> {{page.qualificationsHeader.total}}</span></div>
              <el-table
                border
                :data="qualificationsTableData"
                :cell-style="cellStyle"
                :header-cell-style="headerColor"
                style="width: 100%;">
                <el-table-column type="index" width="50" label="序号"></el-table-column>
                <el-table-column v-for="(item,index) in qualificationsHeader" :key="index" :label="item.label" :property="item.property" align="center">
                  <template #default="scope">
                    <el-button v-if="scope.column.property == 'handleImg'">预览</el-button>
                    <el-button v-else-if="scope.column.property == 'handleDetails'">详情</el-button>
                    <span v-else>{{scope.row[scope.column.property]}}</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table-page">
                <el-pagination
                  background
                  hide-on-single-page="true"
                  :page-size="10"
                  :pager-count="7"
                  layout="prev, pager, next"
                  v-model:currentPage="page.qualificationsHeader.pageNum"
                  @current-change="qualificationsChange"
                  :total="page.qualificationsHeader.total">
                </el-pagination>
              </div>
            </block>
            <!-- 荣誉信息 -->
            <block>
              <div id="honorHeader" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
              <div class="table-list-title dis-bet-cen">
                <div>荣誉信息<span style="color: #5D6FE9;">{{page.honorHeader.total}}</span></div>
                <el-select v-model="honorValue" @change="handleHonorSelect" placeholder="请选择" >
                  <el-option
                    v-for="item in honorOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    <span>{{ item.label }}</span>
                  </el-option>
                </el-select>
              </div>
              <el-table
                border
                :data="honorTableData"
                :cell-style="cellStyle"
                :header-cell-style="headerColor"
                style="width: 100%;">
                <el-table-column type="index" width="50" label="序号"></el-table-column>
                <el-table-column v-for="(item,index) in honorHeader" :key="index" :label="item.label" :property="item.property" align="center">
                  <template #default="scope">
                    <el-button v-if="scope.column.property == 'handleImg'">预览</el-button>
                    <el-button v-else-if="scope.column.property == 'handleDetails'">详情</el-button>
                    <span v-else>{{scope.row[scope.column.property]}}</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table-page">
                <el-pagination
                  background
                  hide-on-single-page="true"
                  :page-size="10"
                  :pager-count="7"
                  layout="prev, pager, next"
                  v-model:currentPage="page.honorHeader.pageNum"
                  @current-change="honorChange"
                  :total="page.honorHeader.total">
                </el-pagination>
              </div>
            </block>
            <!-- 技术人员 -->
            <block>
              <div id="personnelHeader" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
              <div class="table-list-title dis-bet-cen">
                <div>技术人员 <span style="color: #5D6FE9;">{{page.personnelHeader.total}}</span></div>
                <el-select v-model="personnelValue" placeholder="请选择" @change="handlePersonSelect">
                    <el-option label="全部" value="0">全部</el-option>
                    <el-option
                      v-for="(item, index) in personnelOption"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                      <span style="float: left">{{ item.label }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.num }}</span>
                    </el-option>
                  </el-select>
                </div>
              <el-table
                border
                :data="personnelTableData"
                :cell-style="cellStyle"
                :header-cell-style="headerColor"
                style="width: 100%;">
                <el-table-column type="index" width="50" label="序号"></el-table-column>
                <el-table-column v-for="(item,index) in personnelHeader" :key="index" :label="item.label" :property="item.property" align="center">
                  <template #default="scope">
                    <el-button v-if="scope.column.property == 'handleImg'">预览</el-button>
                    <el-button v-else-if="scope.column.property == 'handleDetails'">详情</el-button>
                    <span v-else>{{scope.row[scope.column.property]}}</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table-page">
                <el-pagination
                  background
                  hide-on-single-page="true"
                  :page-size="10"
                  :pager-count="7"
                  layout="prev, pager, next"
                  v-model:currentPage="page.personnelHeader.pageNum"
                  @current-change="personnelChange"
                  :total="page.personnelHeader.total">
                </el-pagination>
              </div>
            </block>
            <!-- 项目业绩 -->
            <block>
              <div id="projectHeader" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
              <div class="table-list-title dis-bet-cen">
                <div>项目业绩 <span style="color: #5D6FE9;">{{page.projectHeader.total}}</span></div>
                <el-select v-model="projectValue" placeholder="请选择" @change="handleProjectSelect">
                  <el-option label="全部" value="9">全部</el-option>
                  <el-option
                    v-for="(item, index) in projectOption"
                    :key="index"
                    :label="item.label"
                    :value="index">
                    <span style="float: left">{{ item.label }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.num }}</span>
                  </el-option>
                </el-select>
              </div>
              <el-table
                border
                :data="projectTableData"
                :cell-style="cellStyle"
                :header-cell-style="headerColor"
                style="width: 100%;">
                <el-table-column type="index" width="50" label="序号"></el-table-column>
                <el-table-column v-for="(item,index) in projectHeader" :key="index" :label="item.label" :property="item.property" align="center">
                  <template #default="scope">
                    <el-button v-if="scope.column.property == 'handleImg'">预览</el-button>
                    <el-button type="text" v-else-if="scope.column.property == 'handleDetails'" @click="companyAchievementNameClick(scope.row)">详情</el-button>
                    <span v-else>{{scope.row[scope.column.property]}}</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table-page">
                <el-pagination
                  background
                  hide-on-single-page="true"
                  :page-size="10"
                  :pager-count="7"
                  v-model:currentPage="page.projectHeader.pageNum"
                  @current-change="projectChange"
                  layout="prev, pager, next"
                  :total="page.projectHeader.total">
                </el-pagination>
              </div>
            </block>
            <!-- 知识产权 专利信息 -->
            <block>
              <div id="intellectualPatentHeader" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
              <div class="table-list-title">知识产权</div>
              <div class="table-list-title-f" style="padding-top: 0;margin-left: 10px;">专利信息 <span style="color: #5D6FE9;">{{page.intellectualPatentHeader.total}}</span></div>
              <el-table
                border
                :data="intellectualTableData"
                :cell-style="cellStyle"
                :header-cell-style="headerColor"
                style="width: 100%;">
                <el-table-column type="index" width="50" label="序号"></el-table-column>
                <el-table-column v-for="(item,index) in intellectualPatentHeader" :key="index" :label="item.label" :property="item.property" align="center">
                  <template #default="scope">
                    <el-button v-if="scope.column.property == 'handleImg'">预览</el-button>
                    <el-button type="text" v-else-if="scope.column.property == 'handleDetails'" @click="companyIntellectualNameClick(scope.row, 'zl')">详情</el-button>
                    <span v-else>{{scope.row[scope.column.property]}}</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table-page">
                <el-pagination
                  background
                  hide-on-single-page="true"
                  :page-size="10"
                  :pager-count="7"
                  layout="prev, pager, next"
                  v-model:currentPage="page.intellectualPatentHeader.pageNum"
                  @current-change="intellectualChange"
                  :total="page.intellectualPatentHeader.total">
                </el-pagination>
              </div>
            </block>
            <!-- 知识产权 软著 -->
            <block>
              <div class="table-list-title-f" style="margin-left: 10px;">软件著作权 <span style="color: #5D6FE9;">{{page.softwarePatentHeader.total}}</span></div>
              <el-table
                border
                :data="softwareTableData"
                :cell-style="cellStyle"
                :header-cell-style="headerColor"
                style="width: 100%;">
                <el-table-column type="index" width="50" label="序号"></el-table-column>
                <el-table-column v-for="(item,index) in softwarePatentHeader" :key="index" :label="item.label" :property="item.property" align="center">
                  <template #default="scope">
                    <el-button v-if="scope.column.property == 'handleImg'">预览</el-button>
                    <el-button v-else-if="scope.column.property == 'handleDetails'">详情</el-button>
                    <span v-else>{{scope.row[scope.column.property]}}</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table-page">
                <el-pagination
                  background
                  hide-on-single-page="true"
                  :page-size="10"
                  :pager-count="7"
                  layout="prev, pager, next"
                  v-model:currentPage="page.softwarePatentHeader.pageNum"
                  @current-change="softwareChange"
                  :total="page.softwarePatentHeader.total">
                </el-pagination>
              </div>
            </block>
            <!-- 税务荣誉 -->
            <block>
              <div id="taxationHeader" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
              <div class="table-list-title">税务荣誉 <span style="color: #5D6FE9;">{{page.taxationHeader.total}}</span></div>
              <el-table
                border
                :data="taxationTableData"
                :cell-style="cellStyle"
                :header-cell-style="headerColor"
                style="width: 100%;">
                <el-table-column type="index" width="50" label="序号"></el-table-column>
                <el-table-column v-for="(item,index) in taxationHeader" :key="index" :label="item.label" :property="item.property" align="center">
                  <template #default="scope">
                    <el-button v-if="scope.column.property == 'handleImg'">预览</el-button>
                    <el-button v-else-if="scope.column.property == 'handleDetails'">详情</el-button>
                    <span v-else>{{scope.row[scope.column.property]}}</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table-page">
                <el-pagination
                  background
                  hide-on-single-page="true"
                  :page-size="10"
                  :pager-count="7"
                  v-model:currentPage="page.taxationHeader.pageNum"
                  @current-change="taxationChange"
                  layout="prev, pager, next"
                  :total="page.taxationHeader.total">
                </el-pagination>
              </div>
            </block>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-affix position="bottom" :offset="0" v-show="islogin && isAuthentication">
      <el-row style="width: 100%;background-color: #fff;border: 1px solid #ebebeb;">
        <el-col :span="24">
          <div class="container" style="height: 68px;line-height: 68px;">
            <div class="dis-sta-cen" style="justify-content: flex-end;">
              <div style="color: #666666;font-size: 16px;margin-right: 20px;">在您提交数据前，请先核对平台已有数据，以防提交重复信息。如有疑问请查看<span style="color: #5D6FE9;cursor: pointer;" @click="handleOpenPDF">操作手册</span></div>
              <el-button style="background-color: #5D6FE9;" type="success" @click="handleToLink()">去维护</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-affix>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AScore from '@/components/data-correction/a-score.vue'
// import BScore from '@/components/data-correction/b-score.vue'
import CScore from '@/components/data-correction/c-score.vue'
import GaussianBlurData from '@/components/gaussian-blur/gaussian-blur-data'
import GaussianBlurAuthen from '@/components/gaussian-blur/gaussian-blur-authen'
import { forwardBG } from '@/api/forward.js'
import { getAllArtisanRelevancy } from '@/api/query.js'
export default {
  name: 'report',
  components: { AScore, CScore, GaussianBlurData, GaussianBlurAuthen }, // BScore,
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  data () {
    return {
      btnActive: '1',
      tableDta: [],
      islogin: false,
      isAuthentication: true, // 是否认证
      isActive: 1,
      bg: require('../../assets/index/bg.png'),
      page: {
        qualificationsHeader: { total: 0, pageNum: 1 },
        honorHeader: { total: 0, pageNum: 1 },
        personnelHeader: { total: 0, pageNum: 1 },
        projectHeader: { total: 0, pageNum: 1 },
        intellectualPatentHeader: { total: 0, pageNum: 1 },
        softwarePatentHeader: { total: 0, pageNum: 1 },
        taxationHeader: { total: 0, pageNum: 1 }
      },
      // 人员分类
      personnelOption: [],
      personnelValue: '0',
      // 荣誉分类
      honorOption: [{
        value: '0',
        label: '全部'
      }, {
        value: '1',
        label: '行业荣誉'
      }, {
        value: '2',
        label: '工商荣誉'
      }],
      honorValue: '0',
      // 项目业绩时间分类
      projectOption: [],
      projectValue: '9',
      // 资质证书
      qualificationsHeader: [{
        label: '资质名称',
        property: 'credentialName'
      }, {
        label: '等级',
        property: 'qualificationGrade'
      }, {
        label: '资质证书编号',
        property: 'credentialNumber'
      }, {
        label: '发证机关',
        property: 'issuedBy'
      }, {
        label: '发证日期',
        property: 'issueDate'
      }, {
        label: '截止日期',
        property: 'closingDate'
      }],
      // 荣誉信息
      honorHeader: [{
        label: '企业荣誉',
        property: 'name'
      }, {
        label: '颁发日期',
        property: 'issueData'
      }, {
        label: '发证机构',
        property: 'issuedBy'
      }],
      // 技术人员
      personnelHeader: [{
        label: '姓名',
        property: 'name'
      }, {
        label: '注册类别',
        property: 'registeredType'
      }, {
        label: '注册专业',
        property: 'registeredProfessional'
      }, {
        label: '执业印章号',
        property: 'certificateno'
      }, {
        label: '有效期至',
        property: 'validPeriod'
      }],
      // 项目业绩
      projectHeader: [{
        label: '中标项目名称',
        property: 'projectName'
      }, {
        label: '中标金额（万元）',
        property: 'money'
      }, {
        label: '中标时间',
        property: 'timeSuccess'
      }, {
        label: '所属省市',
        property: 'province'
      }, {
        label: '操作',
        property: 'handleDetails'
      }],
      // 知识产权 专利信息
      intellectualPatentHeader: [{
        label: '申请日期',
        property: 'applicationDate'
      }, {
        label: '专利名称',
        property: 'title'
      }, {
        label: '法律状态详情',
        property: 'legalStatusDesc'
      }, {
        label: '申请号',
        property: 'publicationNumber'
      }, {
        label: '公布日期',
        property: 'publicationDate'
      }, {
        label: '操作',
        property: 'handleDetails'
      }],
      // 知识产权 软著
      softwarePatentHeader: [{
        label: '软件全称',
        property: 'name'
      }, {
        label: '软件简称',
        property: 'shortname'
      }, {
        label: '版本号',
        property: 'versionno'
      }, {
        label: '发布日期',
        property: 'publicationDate'
      }, {
        label: '登记号',
        property: 'registerNo'
      }, {
        label: '分类号',
        property: 'category'
      }, {
        label: '登记批准日期',
        property: 'registerAperDate'
      }],
      // 税务荣誉
      taxationHeader: [{
        label: '纳税信用等级',
        property: 'level'
      }, {
        label: '评价年度',
        property: 'year'
      }],
      qualificationsTableData: [], // 资质
      honorTableData: [], // 荣誉
      personnelTableData: [], // 技术人员
      projectTableData: [], // 项目业绩
      intellectualTableData: [], // 知识产权专利
      softwareTableData: [],
      taxationTableData: [], // 税务荣誉
      userInfoMes: {}
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ])
  },
  created () {
  },
  watch: {
    idToken: {
      handler (val, oldval) {
        if (this.idToken) {
          this.islogin = true
          document.documentElement.scrollTop = 0
        } else {
          this.islogin = false
        }
      }
    },
    userInfo: {
      handler (val, oldval) {
        if (val) {
          if (this.userInfo) {
            this.userInfoMes = JSON.parse(this.userInfo)
            if (this.userInfoMes.businessInformationStatus === '0') {
              this.isAuthentication = false
            } else {
              this.isAuthentication = true
              this.getCopies()
              // 获取资质
              this.getQualificationsHeader()
              // 获取荣誉
              this.getHonorHeader()
              // 获取人员
              this.initArrangementRadioValueArr() // 获取所有人员分类
              this.getpersonnelHeader()
              // 获取项目业绩
              this.initProjectTime() // 项目业绩年份数量
              this.getProjectHeader()
              // 知识产权
              this.getIntellectualPatentHeader()
              this.getSoftwarePatentHeader()
              // 税务荣誉
              this.getTaxationHeader()
            }
          }
        }
      }
    },
    isAuthentication: {
      handler (val, oldval) {
        this.returnTop('affix', '1')
      }
    }
  },
  mounted () {
    const vm = this
    if (this.idToken) {
      this.islogin = true
    } else {
      this.islogin = false
    }
    if (this.userInfo) {
      this.userInfoMes = JSON.parse(this.userInfo)
      if (this.userInfoMes.businessInformationStatus === '0') {
        this.isAuthentication = false
      } else {
        this.isAuthentication = true
        this.getCopies()
        // 获取资质
        this.getQualificationsHeader()
        // 获取荣誉
        this.getHonorHeader()
        // 获取人员
        this.initArrangementRadioValueArr() // 获取所有人员分类
        this.getpersonnelHeader()
        // 获取项目业绩
        this.initProjectTime() // 项目业绩年份数量
        this.getProjectHeader()
        // 知识产权
        this.getIntellectualPatentHeader()
        this.getSoftwarePatentHeader()
        // 税务荣誉
        this.getTaxationHeader()
        vm.returnTop('affix', '1')
      }
    }
  },
  methods: {
    handleOpenPDF () {
      const url = 'https://img.yijingjia.com/1648097582474_20220324-PC.pdf'
      window.open(url, '_black')
    },
    handleChangeType (e) {
      this.isActive = e
    },
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    handleToLink: function () {
      const vm = this
      if (!vm.islogin) {
        vm.loginEmit()
        return
      }
      if (!vm.isAuthentication) {
        vm.$router.push({ name: 'company-certification' })
        sessionStorage.removeItem('company-certification-name')
        sessionStorage.setItem('company-certification-name', 'data-correction')
      } else {
        vm.$router.push({ name: 'data-maintain' })
      }
    },
    // 获取总数
    getCopies: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/promoteCredit/getCreditCount',
        dateBackgroundRequestType: 'get',
        data: {
          companyName: vm.userInfoMes.enterpriseName
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.page.qualificationsHeader.total = res.credentialCount
          vm.page.honorHeader.total = res.honorImageCount
          vm.page.personnelHeader.total = res.registeredPersonnelCount
          vm.page.projectHeader.total = res.achievementCount
          vm.page.intellectualPatentHeader.total = res.patentCount
          vm.page.softwarePatentHeader.total = res.copyrightsSoftwarecopyrightCount
          vm.page.taxationHeader.total = res.taxcreditCount
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取资质
    getQualificationsHeader: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/promoteCredit/credentialList',
        dateBackgroundRequestType: 'get',
        data: {
          companyName: vm.userInfoMes.enterpriseName,
          pageNum: vm.page.qualificationsHeader.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          res.list.forEach(item => {
            item.closingDate = item.closingDate ? item.closingDate.substring(0, 10) : '-'
            item.issueDate = item.issueDate ? item.issueDate.substring(0, 10) : '-'
            arr.push(item)
          })
          vm.qualificationsTableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 切换荣誉类型
    handleHonorSelect: function () {
      this.page.honorHeader.pageNum = 1
      this.getHonorHeader()
    },
    handlePersonSelect: function () {
      this.page.personnelHeader.pageNum = 1
      this.getpersonnelHeader()
    },
    // 获取荣誉
    getHonorHeader: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/CompanyHonorImage/getHonorByCompanyIdAndTypeNoLimit',
        dateBackgroundRequestType: 'post',
        data: {
          companyId: vm.userInfoMes.companyId,
          honorType: vm.honorValue,
          pageNum: vm.page.honorHeader.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.honorTableData = res.list
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取所有人员分类
    initArrangementRadioValueArr () {
      const vm = this
      getAllArtisanRelevancy().then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          res.forEach(item => {
            if (item.parentId === '0' && item.name !== '信息技术服务标准（ITSS）人员' && item.name !== '职称人员' && item.name !== '系统集成项目经理' && item.name !== '安全评价师') {
              const data = {
                dateBackgroundUrl: '/companyTechnicalPersonnel/getTechnicalPersonnelList',
                dateBackgroundRequestType: 'get',
                data: {
                  companyId: vm.userInfoMes.companyId,
                  keyword: item.name
                }
              }
              forwardBG(data).then(result => {
                if (result.code === 0) {
                  const res = result.data
                  const obj = {
                    label: item.name,
                    value: item.id,
                    num: res.total
                  }
                  arr.push(obj)
                }
              }).catch(error => {
                console.log(error)
              })
            }
          })
          vm.personnelOption = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取技术人员
    getpersonnelHeader: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/esPersonnel/getESPersonnelDetailsListByModel',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.userInfoMes.companyId,
          parentRelevancyId: vm.personnelValue === '0' ? null : vm.personnelValue,
          pageNum: vm.page.personnelHeader.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          res.forEach(item => {
            item.validPeriod = item.validPeriod ? item.validPeriod.substring(0, 10) : '-'
            item.registeredProfessional = item.registeredProfessional ? item.registeredProfessional : '-'
            arr.push(item)
          })
          vm.personnelTableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    initProjectTime: function () {
      const vm = this
      const date = new Date()
      const year = date.getFullYear()
      const data = {
        dateBackgroundUrl: '/companyBid/getCountByBid',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.userInfoMes.companyId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          for (const i in res) {
            if (i === 'other') {
              const obj = {
                label: '其他',
                min: '',
                max: year - 4 + '-12-31 23:59:59',
                num: res[i]
              }
              arr.push(obj)
            } else {
              const obj = {
                label: i,
                min: i + '-01-01 00:00:00',
                max: i + '-12-31 23:59:59',
                num: res[i]
              }
              arr.unshift(obj)
            }
          }
          vm.projectOption = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取项目业绩
    // 切换项目业绩
    handleProjectSelect: function (e) {
      this.page.projectHeader.pageNum = 1
      this.getProjectHeader()
    },
    getProjectHeader: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/esAchievement/newGetBidList',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.userInfoMes.companyId,
          bidTimeFrom: vm.projectValue === '9' ? '' : vm.projectOption[vm.projectValue].min,
          bidTimeTo: vm.projectValue === '9' ? '' : vm.projectOption[vm.projectValue].max,
          pageNum: vm.page.projectHeader.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          res.list.forEach(item => {
            item.timeSuccess = item.timeSuccess ? item.timeSuccess.substring(0, 10) : '-'
            let n = item.money + ''
            n = '000000' + n
            const i = n.indexOf('.')
            if (i !== -1) {
              n = n.substring(0, i) + n.substring(i + 1, n.length)
              const j = n.substring(0, i - 4) + '.' + n.substring(i - 4, n.length)
              item.money = j * 1
            } else {
              item.money = item.money / 10000
            }
            item.province = item.province ? item.province : '-'
            arr.push(item)
          })
          vm.projectTableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 项目详情跳转
    companyAchievementNameClick (item, index) {
      const vm = this
      sessionStorage.removeItem('company-achievement-details-item')
      sessionStorage.setItem('company-achievement-details-item', JSON.stringify(item))
      vm.$router.push({
        name: 'company-achievement-details'
      })
    },
    // 知识产权专利
    // 点击了 公司名 跳转到公司详情
    companyIntellectualNameClick (item, index) {
      const vm = this
      sessionStorage.removeItem('company-intellectual-details-item')
      sessionStorage.setItem('company-intellectual-details-item', JSON.stringify(item))
      if (index === 'zl') {
        vm.$router.push({
          name: 'company-patent-details'
        })
      }
    },
    getIntellectualPatentHeader: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/intellectualPropertyRights/findPatentList',
        dateBackgroundRequestType: 'get',
        data: {
          companyName: vm.userInfoMes.enterpriseName,
          pageNum: vm.page.intellectualPatentHeader.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          res.list.forEach(item => {
            item.applicationDate = item.applicationDate ? item.applicationDate.substring(0, 10) : '-'
            item.publicationDate = item.publicationDate ? item.publicationDate.substring(0, 10) : '-'
            arr.push(item)
          })
          vm.intellectualTableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 知识产权 软著
    getSoftwarePatentHeader: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/intellectualPropertyRights/findCopyrightsSoftwarecopyrightList',
        dateBackgroundRequestType: 'get',
        data: {
          companyName: vm.userInfoMes.enterpriseName,
          pageNum: vm.page.softwarePatentHeader.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          res.list.forEach(item => {
            item.registerAperDate = item.registerAperDate ? item.registerAperDate.substring(0, 10) : '-'
            item.publicationDate = item.publicationDate ? item.publicationDate.substring(0, 10) : '-'
            arr.push(item)
          })
          vm.softwareTableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 税务荣誉
    getTaxationHeader: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/Taxcredit/getTaxcreditHonorListBycompanyIdNoLimit',
        dateBackgroundRequestType: 'post',
        data: {
          companyId: vm.userInfoMes.companyId,
          pageNum: vm.page.taxationHeader.pageNum,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          res.list.forEach(item => {
            item.level = item.level ? ('纳税信用等级' + item.level) : '-'
            item.publicationDate = item.publicationDate ? item.publicationDate.substring(0, 10) : '-'
            arr.push(item)
          })
          vm.taxationTableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    returnTop (e, index) {
      this.btnActive = index
      document.querySelector('#' + e).scrollIntoView()
    },
    projectChange (e) {
      this.page.projectHeader.pageNum = e
      this.getProjectHeader()
    },
    taxationChange (e) {
      this.page.taxationHeader.pageNum = e
      this.getHonorHeader()
    },
    qualificationsChange (e) {
      this.page.qualificationsHeader.pageNum = e
      this.getQualificationsHeader()
    },
    honorChange (e) {
      this.page.honorHeader.pageNum = e
      this.getTaxationHeader()
    },
    softwareChange (e) {
      this.page.softwarePatentHeader.pageNum = e
      this.getSoftwarePatentHeader()
    },
    intellectualChange (e) {
      this.page.intellectualPatentHeader.pageNum = e
      this.getIntellectualPatentHeader()
    },
    personnelChange (e) {
      this.page.personnelHeader.pageNum = e
      this.getpersonnelHeader()
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.affix-container {
  min-height: 1px;
}
.img-content {
  margin-top: -60px;
  min-width: 1200px;
  position: relative;
  .index-search-content {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
    .text-p {
      color: #fff;
      font-size: 40px;
    }
    .btn-text-p {
      width: 400px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      background-color: #FC7A1E;
      color: #fff;
      border-radius: 4px;
      margin: 0 auto;
      margin-top: 80px;
    }
    .no-authentication {
      margin-top: 50px;
      font-size: 16px;
      color: #fff;
    }
  }
}
.table-title-jc {
  background: #FAFBFF;
  border: 1px solid #E6E6E6;
  border-radius: 4px 4px 0px 0px;
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  .table-title-span-jc {
    padding: 0 20px;
    text-align: center;
    display: block;
    color: #666666;
    font-size: 14px;
  }
  .table-title-span-jc.active {
    padding: 0 20px;
    text-align: center;
    color: #5D6FE9;
    font-size: 14px;
  }
}
.table-list {
  padding: 20px;
  padding-top: 0;
  background-color: #fff;
  border: 1px solid #E6E6E6;
  border-radius: 0px 0px 4px 4px;
  border-top: none;
  margin-bottom: 30px;
  .table-list-title {
    padding: 18px 0;
    text-align: left;
    color: #333;
    font-size: 18px;
    font-weight: bold;
  }
  .table-list-title-f {
    padding: 10px 0;
    text-align: left;
  }
  .table-page {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
